import React from "react";
import loading from "./AUSTRAL.gif";

const Loader = () => {
    return (
        <div
            style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "50%",
                marginTop: -432,
                left: "50%",
                marginLeft: -432,
                background: "#f8f8f8ad",
            }}
        >
            {/* aaaaaaaaaaaaa */}
            <img src={loading} alt="loading" />
        </div>
    );
};

export default Loader;
